<style>
  @import "/css/story.css";
</style>

<template>
    <div class="services">
        <Sante></Sante>
    </div>
</template>

<script>
import Sante from '@/components/Services/Sante.vue'

export default {
    name: 'Y-sante',

    components: {
        Sante
    }
}
</script>
